import { useEffect, useState, createElement } from "react";
import { Routes, Route, Outlet, useParams, Link } from "react-router-dom";

import { ConnectButton } from "@rainbow-me/rainbowkit";

import { WagmiConfig, createClient, configureChains } from "wagmi";

import { arbitrum } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";

import {
  getDefaultWallets,
  RainbowKitProvider,
  lightTheme,
} from "@rainbow-me/rainbowkit";

import { Home } from "./pages/home";

const { chains, provider } = configureChains([arbitrum], [publicProvider()]);

const { connectors } = getDefaultWallets({
  appName: "Lemon",
  chains,
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

function Layout() {
  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider
        chains={chains}
        theme={lightTheme({
          accentColor: "rgb(134 239 172)",
          accentColorForeground: "black",
          borderRadius: "medium",
          fontStack: "system",
        })}
      >
        <div>
          <div>
            <div className="pt-1 ml-auto mr-auto w-11/12 lg:w-9/12 min-h-screen flex flex-col justify-between">
              <div className="flex flex-row-reverse mb-6 mt-4 gap-2">
                <ConnectButton accountStatus="address" />
              </div>
              <div className="w-full flex flex-col justify-between grow h-full">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default function App() {
  return (
    <div className="main min-h-screen">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
    </div>
  );
}
