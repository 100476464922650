import { useEffect, useState } from "react";

import {
  usePrepareContractWrite,
  useAccount,
  useContractWrite,
  useProvider,
} from "wagmi";
import { batchCall } from "../executor";
import {abiErc20} from "../executor/abis"

import "./home.css";

const items = [
  {
    name: "eth/usdc",
    pair: "0xe20F93279fF3538b1ad70D11bA160755625e3400",
    guage: "0x0322CEbACF1f235913bE3FCE407F9F81632ede8B",
  },
  // {
  //   name: "dai/usdt",
  //   pair: "0x15b9D20bcaa4f65d9004D2BEBAc4058445FD5285",
  //   guage: "0x637dcef6f06a120e0cca5bca079f6cf6da9264e8",
  // },
];

const guageAbi = [
  {
    inputs: [
      { internalType: "address", name: "account", type: "address" },
      { internalType: "address[]", name: "tokens", type: "address[]" },
    ],
    name: "getReward",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

const refreshState = async (provider) => {
  let x = await batchCall(
    [
      [
        "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
        abiErc20,
        "balanceOf",
        ["0x8d5D3906D2bDdaDe3C867B7D9ff02E901125895A"],
      ],
    ],
    provider
  );
  console.log("bal", x.toString());
};

function Item({ item }) {
  const { address } = useAccount();
  const { config } = usePrepareContractWrite({
    address: item.guage,
    abi: guageAbi,
    functionName: "getReward",
    args: [address, ["0x463913D3a3D3D291667D53B8325c598Eb88D3B0e"]],
  });

  const { write: harvest } = useContractWrite(config);

  // const provider = useProvider();

  // useEffect(() => {
  //   let run = async () => {
  //     await refreshState(provider);
  //   };
  //   run();
  // }, [provider]);

  return (
    <div className="flex flex-row mt-4">
      <div className="w-1/2">{item.name}</div>
      <div>
        <button
          className="w-1/2 Button green"
          onClick={() => {
            harvest?.();
          }}
        >
          harvest
        </button>
      </div>
    </div>
  );
}

export function Home() {
  return (
    <div>
      {items.map((x) => {
        return <Item item={x} key={x.guage}></Item>;
      })}
    </div>
  );
}
